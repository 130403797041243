import {Component, ContextType} from "react";
import {AppBar, Chip, Paper, Toolbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Logout} from "@mui/icons-material";
import CssBaseline from "@mui/material/CssBaseline";
import PowerkoenigLogo from "../assets/logos/powerkoenig.svg";
import Box from "@mui/material/Box";
import {LogoutButton} from "../auth/Login";
import AuthContext from "../auth/AuthContext";
import {AppViewInfo, SideMenu} from "./SideMenu";
import {AppViewContext, AppViewContextProvider} from "./context/AppViewContext";
import {BrowserView, MobileView} from "react-device-detect";
import BatteryDashboardList from "./manager/BatteryDashboardList";
import Link from "@mui/material/Link";
import {deleteAccount} from "../api/auth_api";

type MainAppProps = {}
type MainAppState = {}

export default class MainApp extends Component<MainAppProps, MainAppState> {
	static contextType = AuthContext
	context!: ContextType<typeof AuthContext>

	render() {
		return <AppViewContextProvider>
			<CssBaseline/>
			<AppBar component={"nav"} sx={{zIndex: 100}} position="fixed">
				<Toolbar>
					<Typography
						component="h1"
						variant="h6"
						color="inherit"
						noWrap
						justifyContent="flex-start"
						alignItems="center"
						display="flex"
						sx={{flexGrow: 1}}
					>
						<Box component={"img"} sx={{margin: 1, marginRight: 4, maxWidth: "18vh"}} src={PowerkoenigLogo}/>
						<BrowserView>Battery Dashboard</BrowserView>
					</Typography>
					<BrowserView>
						<Chip variant="outlined" color="secondary" label={this.context.auth.email}/>
					</BrowserView>
					<Link onClick={() => deleteAccount(this.context.token)}>Delete Account</Link>
					<LogoutButton
						color="secondary"
						startIcon={<Logout/>}
						variant="contained"
						sx={{marginLeft: 4}}
					>Logout</LogoutButton>
				</Toolbar>
			</AppBar>
			<BrowserView>
				<SideMenu/>
				<Box component="main" sx={{marginTop: 10, paddingLeft: 30, paddingRight: 2}}>
					<Paper sx={{padding: 3}}>
						<AppViewContext.Consumer>
							{(value) => AppViewInfo[value.view].app}
						</AppViewContext.Consumer>
					</Paper>
				</Box>
			</BrowserView>
			<MobileView>
				<Box component="main" sx={{
					marginTop: 10,
					padding: 2,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					textAlign: "center"
				}}>
					<BatteryDashboardList/>
				</Box>
			</MobileView>
		</AppViewContextProvider>;
	}

}