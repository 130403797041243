import {ApiResponse, ApiResponseSuccess, AuthToken} from "../../../backend/src/types/api";

export async function signUp(
	token: AuthToken,
	firstName: string,
	lastName: string,
	email: string,
	password: string
): Promise<ApiResponseSuccess> {
	const res = await fetch("/api/auth/signup", {
		method: "POST",
		headers: {"Content-Type": "application/json", "Authorization": token},
		body: JSON.stringify({firstName, lastName, email, password})
	});
	if (res.status != 200) {
		return {
			error: "internal_error",
			message: res.statusText,
			success: false
		};
	}
	return await res.json();
}

export async function signIn(email: string, password: string): Promise<ApiResponse<{ token: AuthToken, expires_in_hours: number }>> {
	const res = await fetch("/api/auth/signin", {
		method: "POST",
		headers: {"Content-Type": "application/json"},
		body: JSON.stringify({email, password})
	});
	if (res.status != 200) {
		return {
			error: "internal_error",
			message: res.statusText,
			token: "",
			expires_in_hours: 0
		};
	}
	return await res.json();
}

export async function signOut(token: AuthToken): Promise<ApiResponseSuccess> {
	const res = await fetch("/api/auth/signout", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Authorization": token
		}
	});
	if (res.status != 200) {
		return {
			error: "internal_error",
			message: res.statusText,
			success: false
		};
	}
	return await res.json();
}


export async function deleteAccount(token: AuthToken): Promise<ApiResponseSuccess> {
	const res = await fetch("/api/auth/delete", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Authorization": token
		}
	});
	if (res.status != 200) {
		return {
			error: "internal_error",
			message: res.statusText,
			success: false
		};
	}
	return await res.json();
}
